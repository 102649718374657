<template>
  <div
  v-if="elementData"
  >
    <b-form
      @submit.prevent="save"
    >
    <validation-observer ref="elementForm" #default="{ invalid }">
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">Información Principal</span>
          </div>
        </b-card-header>
        <b-card-body>
        
            <b-row>
              <b-col
                cols="4"
                xl="4"
              >
                <b-form-group
                    label-for="hours"
                >
                <feather-icon
                        icon="StarIcon"
                        class="mr-75"
                  />
                  <span class="font-weight-bold">{{$t('edition_time.object.hours')}}</span><span class="text-danger">*</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('edition_time.object.hours')"
                    rules="required"
                  >
                    <b-form-input
                      id="hours"
                      :placeholder="$t('edition_time.object.hours')"
                      v-model="elementData.hours"
                      type="number"
                      :formatter="hoursFormat"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
                xl="4"
              >
                <b-form-group
                    label-for="minutes"
                >
                <feather-icon
                        icon="StarIcon"
                        class="mr-75"
                  />
                  <span class="font-weight-bold">{{$t('edition_time.object.minutes')}}</span><span class="text-danger">*</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('edition_time.object.minutes')"
                    rules="required"
                  >
                  
                    <b-form-input
                      id="minutes"
                      :placeholder="$t('edition_time.object.minutes')"
                      v-model="elementData.minutes"
                      type="number"
                      :formatter="minutesFormat"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-button
                variant="primary"
                type="submit"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :disabled="invalid"
              >
              {{$t('actions.save')}}
              </b-button>
            </b-row>
        </b-card-body>
      </b-card>
    </validation-observer>
    </b-form>
  </div>
</template>

<script>
import {
  BFormTextarea, BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BBadge, BFormInput, BFormGroup, BForm, BFormSelect, BFormCheckbox, BAlert, BLink
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import router from '@/router'
import store from '@/store'
import elementStoreModule from '../storeModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormTextarea, BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BBadge, BFormInput, BFormGroup, BForm, BFormSelect, BFormCheckbox, BAlert, BLink, ValidationProvider, ValidationObserver
  },
  data() {
    return {
      ARL_CODES_APP_STORE_MODULE_NAME: 'app-edition-time',
      RESOURCES_ELEMENT_NAME: 'edition_time',
      elementData: undefined,
      required
    }
  },
  async mounted() {
    // Register module
    if (!store.hasModule(this.ARL_CODES_APP_STORE_MODULE_NAME)) store.registerModule(this.ARL_CODES_APP_STORE_MODULE_NAME, elementStoreModule)
    
    this.elementData = await this.fetchElement()
    
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ARL_CODES_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ARL_CODES_APP_STORE_MODULE_NAME)
  },
  methods: {
    minutesFormat(value) {
      if (!value) {
        return undefined
      }
      var ret = parseInt(value).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        })
        return ret>60 ? 60: ret
    },
    hoursFormat(value) {
      if (!value) {
        return undefined
      }
        return value>9999 ? 9999: value
    },
    async fetchElement() {
      this.newElement = false
      return store.dispatch(this.ARL_CODES_APP_STORE_MODULE_NAME + '/fetch_element', { id: 1 })
        .then(response => { 
          return response.data 
        })
        .catch(error => {
          return undefined
        })
    },
    save() {
      console.log('saving')
      this.$refs.elementForm.validate().then(success => {
        console.log('valid', success)
        if (success) {
          console.log('sending')
          this._save(this.elementData)
        }
      })
    },
    _save(elementData) {
      let method = this.ARL_CODES_APP_STORE_MODULE_NAME + '/save_element'
      this.newElement = false
      var _self = this
      store
        .dispatch(method, {data: elementData})
        .then(response => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.update.title'),
              icon: 'SaveIcon',
              variant: 'success',
              text: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.update.success'),
            },
          })
        })
        .catch((error) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.update.title'),
              icon: 'SaveIcon',
              variant: 'danger',
              text: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.update.failure'),
            },
          })
        })
    }
  }
}
</script>

<style>

</style>
